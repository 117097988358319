import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Banner from '../components/Banner';
import Layout from '../components/layout';
import SEO from '../components/Seo';
import gildformLogo from '../images/gildformlogo-black.png';
import { Button } from 'react-bootstrap';
import sendIcon from '../images/sendIconW.webp';
import {
  brandForgeTextComplete,
  deleteBrandForgeChat,
  getErpMembershipContent,
  getUserBrandForgeMessages,
  updateBrandForgeChatName,
} from '../utils/api';
import { clickHandler, formatDate, generateUUID } from '../utils/helpers';
import { navigate } from '@reach/router';
import WindoWidth from '../components/WindowWidth';
import { useAutosizeTextArea } from '../components/PopUpChat';
import editIcon from '../images/edit-white.png';
import saveIcon from '../images/save-white.png';
import deleteIcon from '../images/delete-white1.png';
import Modal from '../components/Modal';
import MembershipSelect from '../components/MembershipPopup';
import { changeMembership } from '../state/login/thunks';
import Prompts from '../components/Prompts';
import PrivateRoute from '../components/auth/protected';

const BrandForge = (props) => {
  const windowWidth = WindoWidth();
  const [openSidebar, setOpenSidebar] = useState(true);
  const [isEditingChatName, setIsEditingChatName] = useState({
    id: '',
    isEditing: false,
  });
  const [cndOpen, setCndOpen] = useState(windowWidth > 768);
  const [showBrandForgeDesc, setShowBrandForgeDesc] = useState(
    windowWidth > 768 ? true : false
  );
  const [activeTab, setActiveTab] = useState('new-chat');
  const [messages, setMessages] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [selectedChat, setSelectedChat] = useState('');
  const [selectedChatName, setSelectedChatName] = useState({
    chat_id: '',
    chat_name: '',
  });
  const [currentUUID, setCurrentUUID] = useState('');
  const [currentChatName, setCurrentChatName] = useState('');
  const [msgLoader, setMsgLoader] = useState(false);
  const [openChatHistoryCollapse, setOpenChatHistoryCollapse] = useState(false);
  const [userChats, setUserChats] = useState([]);
  const [
    showDeleteChatConfirmationModal,
    setShowDeleteChatConfirmationModal,
  ] = useState(false);
  const [toDeleteSelectedChat, setToDeleteSelectedChat] = useState({});
  const [errMsg, setErrMsg] = useState(false);
  const [msgsLength, setMsgsLength] = useState(0);
  const [chosenMembership, setChosen] = useState(null);
  const [erpMembershipContent, setErpMembershipContent] = useState([]);
  const [mmbLoading, setMmbLoad] = useState(false);
  const [isMemberModalOpen, openMemberModal] = useState(false);
  const [openPrompts, setOpenPrompts] = useState(false);
  const [selectedPlan, _setSelectedPlan] = useState({});

  const [inputMessage, setInputMessage] = useState('');
  const chatBoxRef = useRef(null);
  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, inputMessage);

  const handleSendMessage = async () => {
    setOpenPrompts(false);
    setMsgLoader(true);
    let generatedUUID;
    let chatName;
    if (messages.length > 0) {
      if (messages[0].chat_name === 'New chat') {
        const splittedSentence = messages[0].user_text.split('.');
        chatName = splittedSentence[0];
      } else {
        chatName = messages[0].chat_name;
      }
      generatedUUID = messages[0].chat_id;
    } else {
      if (!currentUUID) {
        generatedUUID = generateUUID();
        const splittedSentence = inputMessage.split('.');
        chatName = splittedSentence[0];
      } else {
        generatedUUID = currentUUID;
        const splittedSentence = inputMessage.split('.');
        chatName = splittedSentence[0];
      }
    }
    messages.push({
      ai_text: null,
      user_text: inputMessage,
      chat_id: generatedUUID,
      chat_name: chatName,
      deleted: false,
      from: 'user',
      name: `${props.profile.firstName} ${props.profile.lastName}`,
      userId: props.profile.sub,
      createdAt: formatDate(new Date(), true),
      updatedAt: formatDate(new Date(), true),
    });
    setInputMessage(null);
    const data = {
      text: inputMessage ? inputMessage : '',
      userId: props.profile.sub,
      name: `${props.profile.firstName} ${props.profile.lastName}`,
      chat_id: generatedUUID,
      chat_name: chatName,
    };
    const res = await brandForgeTextComplete(data);
    if (res.status === 200) {
      const dd = await res.json();
      setMsgsLength(msgsLength + 1);
      messages.pop();
      const initalData = {
        id: dd.brandForge.id,
        chat_id: dd.brandForge.chat_id,
        chat_name: dd.brandForge.chat_name,
        deleted: dd.brandForge.deleted,
        userAuth0Id: dd.brandForge.userAuth0Id,
        user_name: dd.brandForge.user_name,
        createdAt: dd.brandForge.createdAt,
        updatedAt: dd.brandForge.updatedAt,
      };
      const userMessage = {
        ...initalData,
        from: 'user',
        user_text: dd.brandForge.user_text,
      };
      const aiMessage = {
        ...initalData,
        from: 'brandforge',
        ai_text: dd.brandForge.ai_text,
      };
      let first_sentence = dd.brandForge.user_text.split('.');
      const newChat = {
        id: dd.brandForge.id,
        chat_id: dd.brandForge.chat_id,
        chat_name: dd.brandForge.chat_name,
        deleted: dd.brandForge.deleted,
        userAuth0Id: dd.brandForge.userAuth0Id,
        user_name: dd.brandForge.user_name,
        startedAt: dd.brandForge.createdAt,
        updatedAt: dd.brandForge.updatedAt,
      };
      const chatExistsInChatList = userChats.find(
        (item) => item.chat_id === dd.brandForge.chat_id
      );
      if (!chatExistsInChatList) {
        setUserChats([...userChats, newChat]);
      }

      let messageErr = {
        from: 'brandforge',
        user_name: `${props.profile.firstName} ${props.profile.lastName}`,
        userAuth0Id: props.profile.sub,
        error: true,
      };
      if (props.profile.membership.name === 'Standard') {
        if (msgsLength >= 10) {
          setErrMsg(true);
          setMessages((prevState) => [
            ...prevState,
            {
              ...messageErr,
              ai_text: `<div><bold>${msgsLength}/10</bold> calls used this month. Upgrade your membership <b style='cursor:pointer'>here</b> to get unlimited access.</div>`,
            },
          ]);
        }
      } else if (
        props.profile.membership.name.includes('Silver Plus') ||
        props.profile.membership.name === 'Bronze' ||
        props.profile.membership.name === 'Silver'
      ) {
        if (msgsLength >= 20) {
          setErrMsg(true);
          setMessages((prevState) => [
            ...prevState,
            {
              ...messageErr,
              ai_text: `<div><bold>${msgsLength}/20</bold> calls used this month. Upgrade your membership <b style='cursor:pointer'>here</b> to get unlimited access.</div>`,
            },
          ]);
        }
      }
      setMessages([...messages, userMessage, aiMessage]);
      setAllMessages([...allMessages, userMessage, aiMessage]);
      setMsgLoader(false);
    } else {
      setMsgLoader(false);
    }
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight);
    }
  }, [messages, activeTab, msgLoader]);

  const getUserMsgs = async (id) => {
    const res = await getUserBrandForgeMessages(id);
    const dd = await res.json();
    if (res.status === 200) {
      setMsgsLength(dd.length);
      const allSpecifiedMessages = [];
      dd.map((item) => {
        const initalData = {
          id: item.id,
          chat_id: item.chat_id,
          chat_name: item.chat_name,
          deleted: item.deleted,
          userAuth0Id: item.userAuth0Id,
          user_name: item.user_name,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
        };
        const userMessage = {
          ...initalData,
          from: 'user',
          user_text: item.user_text,
        };
        const aiMessage = {
          ...initalData,
          from: 'brandforge',
          ai_text: item.ai_text,
        };
        allSpecifiedMessages.push(userMessage);
        allSpecifiedMessages.push(aiMessage);
      });

      const chats = allSpecifiedMessages.reduce((acc, message) => {
        const chat = acc.find((c) => c.chat_id === message.chat_id);
        if (chat) {
          if (message.createdAt < chat.startedAt) {
            chat.startedAt = message.createdAt;
          }
        } else {
          acc.push({
            id: message.id,
            chat_id: message.chat_id,
            chat_name: message.chat_name,
            deleted: message.deleted,
            userAuth0Id: message.userAuth0Id,
            user_name: message.user_name,
            startedAt: message.createdAt,
            updatedAt: message.updatedAt,
          });
        }
        return acc;
      }, []);
      let msg = {
        user_name: `${props.profile.firstName} ${props.profile.lastName}`,
        userAuth0Id: props.profile.sub,
        error: true,
        from: 'brandforge',
        ai_text: null,
      };
      if (props.profile.membership.name.trim() === 'Standard') {
        if (dd.length <= 10) {
          msg = {
            ...msg,
            ai_text: `<div><bold>${dd.length}/10</bold> calls used this month. Upgrade your membership <b style='cursor:pointer; text-decoration:underline'>here</b> to get unlimited access.</div>`,
          };
        } else {
          setErrMsg(true);
          msg = {
            ...msg,
            ai_text: `<div><bold>${dd.length}/10</bold> calls used this month. Upgrade your membership <b style='cursor:pointer; text-decoration:underline'>here</b> to get unlimited access.</div>`,
          };
        }
      } else if (
        props.profile.membership.name.trim() === 'Silver Plus' ||
        props.profile.membership.name.trim() === 'Bronze' ||
        props.profile.membership.name.trim() === 'Silver'
      ) {
        if (dd.length >= 0 && dd.length < 20) {
          msg = {
            ...msg,
            ai_text: `<div><bold>${dd.length}/20</bold> calls used this month. Upgrade your membership <b style='cursor:pointer; text-decoration:underline'>here</b> to get unlimited access.</div>`,
          };
        } else if (dd.length >= 20) {
          setErrMsg(true);
          msg = {
            ...msg,
            ai_text: `<div><bold>${dd.length}/20</bold> calls used this month. Upgrade your membership <b style='cursor:pointer; text-decoration:underline'>here</b> to get unlimited access.</div>`,
          };
        }
      }
      if (activeTab === 'new-chat') {
        setMessages(msg.ai_text ? [msg] : []);
      } else {
        setMessages(
          msg.ai_text
            ? [...allSpecifiedMessages.filter((itm) => !itm.delete), msg]
            : [allSpecifiedMessages.filter((itm) => !itm.delete)]
        );
      }
      setAllMessages(allSpecifiedMessages.filter((itm) => !itm.delete));
      setUserChats(chats);
    }
  };

  const handleChatSelection = (chat_id, first) => {
    setActiveTab('chat-history');
    if (first) {
      let firstChatId;
      if (userChats.length > 0) {
        firstChatId = userChats[0].chat_id;
      } else {
        firstChatId = '';
      }
      setSelectedChat(firstChatId);
      const newArr = allMessages.filter((item) => item.chat_id === firstChatId);
      setMessages(newArr);
    } else {
      setSelectedChat(chat_id);
      const newArr = allMessages.filter((item) => item.chat_id === chat_id);
      setMessages(newArr);
    }
  };

  const handleUpdateChatName = async () => {
    const response = await updateBrandForgeChatName(
      selectedChatName.chat_id,
      {
        chat_name: selectedChatName.chat_name,
      },
      props.token
    );
    const res = await response.json();
    if (response.status === 200) {
      const newArr = allMessages.map((msg) => {
        if (msg.chat_id === selectedChatName.chat_id) {
          return {
            ...msg,
            chat_name: selectedChatName.chat_name,
          };
        } else {
          return msg;
        }
      });
      const chats = newArr.reduce((acc, message) => {
        const chat = acc.find((c) => c.chat_id === message.chat_id);
        if (chat) {
          if (message.createdAt < chat.startedAt) {
            chat.startedAt = message.createdAt;
          }
        } else {
          acc.push({
            id: message.id,
            chat_id: message.chat_id,
            chat_name:
              message.chat_id === selectedChatName.chat_id
                ? selectedChatName.chat_name
                : message.chat_name,
            deleted: message.deleted,
            userAuth0Id: message.userAuth0Id,
            user_name: message.user_name,
            startedAt: message.createdAt,
            updatedAt: message.updatedAt,
          });
        }
        return acc;
      }, []);
      const filteredChats = chats.filter((item) => item.deleted == false);
      setUserChats(filteredChats);
      setAllMessages(newArr);
      setSelectedChatName({
        chat_id: '',
        chat_name: '',
      });
      setIsEditingChatName({
        id: '',
        isEditing: false,
      });
    }
  };

  const handleDeleteChat = async () => {
    const response = await deleteBrandForgeChat(
      toDeleteSelectedChat.chat_id,
      props.token
    );
    const res = await response.json();
    if (response.status === 200) {
      const newArr = userChats.filter(
        (item) => item.chat_id !== toDeleteSelectedChat.chat_id
      );
      setUserChats(newArr);
      let firstChatId;
      if (newArr.length > 0) {
        firstChatId = newArr[0].chat_id;
      } else {
        firstChatId = '';
      }
      setSelectedChat(firstChatId);
      const newMessagesArr = allMessages.filter(
        (item) => item.chat_id === firstChatId
      );
      const newDeletedMessages = allMessages.map((item) => {
        if (item.chat_id === toDeleteSelectedChat.chat_id) {
          return {
            ...item,
            deleted: '1',
          };
        } else {
          return item;
        }
      });
      setAllMessages(newDeletedMessages);
      setMessages(newMessagesArr);
      setShowDeleteChatConfirmationModal(false);
    }
  };

  const fetchErpMembershipContent = async () => {
    setMmbLoad(true);
    const res = await getErpMembershipContent();
    setErpMembershipContent(res ? res.filter((it) => it.active) : []);
    setMmbLoad(false);
  };

  useEffect(() => {
    if (isMemberModalOpen && erpMembershipContent.length === 0) {
      fetchErpMembershipContent();
    }
  }, [isMemberModalOpen]);

  useEffect(() => {
    getUserMsgs(props.profile.id);
  }, []);

  useEffect(() => {
    if (cndOpen) {
      setTimeout(() => {
        setShowBrandForgeDesc(true);
      }, 1000);
    } else {
      setShowBrandForgeDesc(false);
    }
  }, [cndOpen]);

  const stylePr = {
    whiteSpace: 'pre-line',
  };

  let dsblSend;
  if (props.profile) {
    const mmb = props.profile.membership.metadata
      ? props.profile.membership.metadata.nickname
        ? props.profile.membership.metadata.nickname
        : props.profile.membership.name.trim()
      : props.profile.membership.name.trim();
    dsblSend =
      mmb === 'Standard'
        ? msgsLength >= 10
          ? true
          : false
        : mmb.includes('Silver Plus') || mmb === 'Bronze' || mmb === 'Silver'
          ? msgsLength >= 20
            ? true
            : false
          : false;
  }

  return (
    <>
      <Modal
        title={`Delete ${toDeleteSelectedChat && toDeleteSelectedChat.chat_name
            ? `"${toDeleteSelectedChat.chat_name}"`
            : ''
          }`}
        description=''
        show={showDeleteChatConfirmationModal}
        handleClose={() => setShowDeleteChatConfirmationModal(false)}
        primaryButtons={[
          {
            title: 'Cancel',
            handle: () => setShowDeleteChatConfirmationModal(false),
          },
        ]}
        dangerButtons={[{ title: 'Delete', handle: handleDeleteChat }]}
        deleteModal={true}
      >
        <span>Are you sure you want to delete this chat?</span>
      </Modal>
      {props.profile && !props.profile.answers ? <Banner /> : null}
      <PrivateRoute>
        <Layout
          light_header={undefined}
          sit_on_top
          className='home'
          noAnswers={props.profile && !props.profile.answers ? true : false}
          brandforge={true}
        >
          <SEO title='BrandForge' />
          <div className='brandforge-container'>
            <div className={cndOpen ? 'sidebar opened' : 'sidebar closed'}>
              <div className='sidebar-header'>
                <div className='image-text'>
                  <img src={gildformLogo} alt='gild-logo' />
                  <span>BRANDFORGE™</span>
                </div>
                {showBrandForgeDesc && (
                  <div className='brandforge-desc'>
                    <span>
                      Hi there, I am BRANDFORGE™, Gildform's AI-powered
                      assistant designed to help you build and grow your brand
                      from concept to production and beyond.
                    </span>
                    <span>
                      Tell me how I can help and I will support you with
                      end-to-end support to help your turn their ideas into
                      reality and achieve success in the market.
                    </span>
                  </div>
                )}
              </div>
              <div
                className={
                  activeTab === 'new-chat' ? 'nav-item active' : 'nav-item'
                }
                onClick={() => {
                  setOpenChatHistoryCollapse(false);
                  setSelectedChat('');
                  if (activeTab !== 'new-chat') {
                    const uuid = generateUUID();
                    setCurrentUUID(uuid);
                    setCurrentChatName('New Chat');
                  }
                  setMessages([]);
                  setActiveTab('new-chat');
                }}
              >
                <div className='icon-container'>
                  <i className='fas fa-comments' />
                </div>
                <span className='nav-title'>New Chat</span>
              </div>
              <div className='nav-item chat-history'>
                <div
                  className={
                    activeTab === 'chat-history'
                      ? 'nav-item-content active'
                      : 'nav-item-content'
                  }
                  onClick={() => {
                    handleChatSelection('', true);
                    setOpenChatHistoryCollapse(!openChatHistoryCollapse);
                  }}
                >
                  <div className='icon-container'>
                    <i className='fas fa-download' />
                  </div>
                  <span className='nav-title'>Chat History</span>
                </div>
                <div
                  className={
                    openChatHistoryCollapse
                      ? 'chat-history-content-collapse open'
                      : 'chat-history-content-collapse'
                  }
                >
                  <div className='chat-history-container'>
                    {userChats &&
                      userChats.map((chat, index) => (
                        <div
                          className={
                            selectedChat === chat.chat_id
                              ? 'chat-row active'
                              : 'chat-row'
                          }
                          key={index}
                          onClick={() =>
                            handleChatSelection(chat.chat_id, false)
                          }
                        >
                          {isEditingChatName.id === chat.chat_id &&
                            isEditingChatName.isEditing ? (
                            <input
                              type='text'
                              value={selectedChatName.chat_name || ''}
                              name='chat-name'
                              onChange={(e) =>
                                setSelectedChatName({
                                  ...selectedChatName,
                                  chat_name: e.target.value,
                                })
                              }
                            />
                          ) : (
                            <span
                              className='chat-name'
                              style={{ maxWidth: '135px' }}
                            >
                              {chat && chat.chat_name
                                ? chat.chat_name.length > 17
                                  ? chat.chat_name.substr(0, 19) + '...'
                                  : chat.chat_name
                                : ''}
                            </span>
                          )}
                          <div className='action-container'>
                            {isEditingChatName.id === chat.chat_id &&
                              isEditingChatName.isEditing ? (
                              <img
                                src={saveIcon}
                                alt='save'
                                onClick={() => {
                                  handleUpdateChatName();
                                }}
                              />
                            ) : (
                              <img
                                src={editIcon}
                                alt='edit'
                                onClick={() => {
                                  setSelectedChatName({
                                    chat_id: chat.chat_id,
                                    chat_name: chat.chat_name,
                                  });
                                  setIsEditingChatName({
                                    id: chat.chat_id,
                                    isEditing: true,
                                  });
                                }}
                              />
                            )}
                            <img
                              src={deleteIcon}
                              alt='delete'
                              onClick={() => {
                                setShowDeleteChatConfirmationModal(true);
                                setToDeleteSelectedChat(chat);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className='nav-item'>
                <div className='icon-container'>
                  <i className='fas fa-store' />
                </div>
                <span className='nav-title'>
                  <a
                    href='https://marketplace.gildform.com/projects-dashboard'
                    target='__blank'
                  >
                    Create a Design Project
                  </a>
                </span>
              </div>
              <div
                className='nav-item'
                onClick={() => {
                  navigate('/model');
                }}
              >
                <div className='icon-container'>
                  <i className='fas fa-plus' />
                </div>
                <span className='nav-title'>Produce Now</span>
              </div>
              <div
                className='show-sidebar'
                onClick={() => {
                  setCndOpen(!cndOpen);
                  setOpenChatHistoryCollapse(false);
                }}
              >
                <div className='icon-content'>
                  {cndOpen ? (
                    <i class='fas fa-chevron-left' />
                  ) : (
                    <i className='fas fa-chevron-right' />
                  )}
                </div>
              </div>
            </div>
            <div
              className={
                cndOpen
                  ? 'brandforge-content sidebar-open'
                  : 'brandforge-content'
              }
            >
              <div className='chat-container'>
                <div className='disclaimer'>
                  By using BrandForge™, you agree to our full disclaimer
                  regarding the accuracy, applicability, and limitations of the
                  AI assistant's advice, which can be found{' '}
                  <a
                    href='https://support.gildform.com/brandforge-disclaimer'
                    target='_blank'
                  >
                    here
                  </a>
                  .
                </div>
                <div className='messages-container' ref={chatBoxRef}>
                  {messages.map((msg, index) => (
                    <div
                      className={`message ${msg.from === 'user' ? 'sent' : 'received'
                        }`}
                      key={index}
                    >
                      <div
                        className={
                          msg.from === 'brandforge'
                            ? 'brandforge avatar'
                            : 'user avatar'
                        }
                      >
                        {msg.from === 'user' ? (
                          <span>{`${props.profile.firstName
                            .charAt(0)
                            .toUpperCase()}${props.profile.lastName
                              .charAt(0)
                              .toUpperCase()}`}</span>
                        ) : (
                          <img
                            src={gildformLogo}
                            alt='gild-logo'
                            height={30}
                            width={30}
                          />
                        )}
                      </div>
                      {msg.from === 'brandforge' ? (
                        <div
                          className='text'
                          style={
                            msg.error ? { color: 'red', ...stylePr } : stylePr
                          }
                        >
                          {msg.error ? (
                            <span
                              className='message-content'
                              dangerouslySetInnerHTML={{ __html: msg.ai_text }}
                              onClick={(e) => clickHandler(e, openMemberModal)}
                            />
                          ) : (
                            <span className='message-content'>
                              {msg.ai_text}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className='text' style={stylePr}>
                          {msg.user_text}
                        </div>
                      )}
                    </div>
                  ))}
                  <Prompts
                    setMsg={setInputMessage}
                    setOpenPrompts={setOpenPrompts}
                    openPrompts={openPrompts}
                  />
                </div>
                {msgLoader && (
                  <div className='spinner' aria-busy='true'>
                    <span className='bubble1' />
                    <span className='bubble2' />
                    <span className='bubble3' />
                  </div>
                )}
                <div className='input-message-container'>
                  <textarea
                    id='review-text'
                    onChange={(e) => setInputMessage(e.target.value)}
                    placeholder='Ask BrandForge...'
                    ref={textAreaRef}
                    rows={1}
                    value={inputMessage ? inputMessage : ''}
                    disabled={msgLoader || errMsg || dsblSend}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        if (
                          inputMessage &&
                          inputMessage !== '' &&
                          !errMsg &&
                          !dsblSend
                        ) {
                          handleSendMessage();
                        }
                      }
                    }}
                  />
                  <div className='send-message-button-container'>
                    <Button
                      id='send-message'
                      className='send-message-button'
                      onClick={handleSendMessage}
                      disabled={msgLoader || errMsg || dsblSend}
                    >
                      <img src={sendIcon} alt='send' />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MembershipSelect
            showCard={false}
            show={isMemberModalOpen}
            handleClose={() => openMemberModal(false)}
            loading={mmbLoading}
            plan={(chosenMembership && chosenMembership) || {}}
            editPlan={chosenMembership ? chosenMembership : null}
            editProfile={true}
            erpMembershipContent={erpMembershipContent && erpMembershipContent}
            selectedPlan={selectedPlan}
            set_SelectedPlan={_setSelectedPlan}
            submitMembership={async (_, membership) => {
              const res = await props.changeMembership(
                props.profile.subUser
                  ? props.profile.profile.admin_id
                  : props.profile.id,
                membership
              );
              if (res.membership) {
                toast.success('Membership edited successfully!', {
                  className: 'bg-primary text-white',
                });
                setChosen(res.membership);
              } else {
                toast.error('An error occurred editing membership!');
              }
              openMemberModal(false);
            }}
            setChosen={setChosen}
            cardInfo={props.profile ? props.profile.cardInfo : null}
          />
        </Layout>
      </PrivateRoute>
    </>
  );
};

const mapStateToProps = (state) => ({
  itemConfigs: state.itemConfigs.configs,
  profile: state.login.auth && state.login.auth.profile,
  token: state.login.auth && state.login.auth.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  changeMembership: (userID, membership) =>
    dispatch(changeMembership(userID, membership)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandForge);
